const renderCarbonAuto = () => {
  if (document.getElementById("carbonads")) return

  const zones = document.querySelectorAll("[data-zone-carbon_auto]")
  zones.forEach(zone => {
    appendScript(zone, zone.getAttribute("data-zone-carbon_auto"))
  })
}

const appendScript = (zone, code) => {
  const script = document.createElement("script")
  script.setAttribute("async", "async")
  script.src =
    `//cdn.carbonads.com/carbon.js?serve=${code}&placement=carbonadsnet`
  script.id = "_carbonads_js"
  zone.appendChild(script)
}

export { renderCarbonAuto }
